.container {
	max-width: 600px;
	margin: 0 auto;
    margin-top: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.preview-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.preview-image {
	width: 100px;
	height: 100px;
	margin: 5px;
}

.password-input-container {
	margin-top: 20px;
}

.password-label {
	font-weight: bold;
	margin-bottom: 5px;
}

.password-input {
	width: 100%;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
	margin-bottom: 10px;
}

.password-input:focus {
	outline: none;
	border-color: #007bff;
}
