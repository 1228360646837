* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.wrapper {
	width: 100vw;
	height: 100vh;
	overflow: hidden;

	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: #26010f;
}

.image-hiding {
  opacity: 100%;
}

.image-showing {
  opacity: 0;
}

.image {
  opacity: 100%;
}

@keyframes hide {
	0% {
		opacity: 100%;
	}
	100% {
		opacity: 0;
	}
}

@keyframes show {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 100%;
	}
}
